import React, { useState } from "react";
import Scroll from "../Scroll";

function CardPerson({ person }) {
  return (
    <tr>
      <td className="p-3">{person.nome}</td>
    </tr>
  );
}

function SearchPersonList({ filteredPersons }) {
  const filtered = filteredPersons.map((person) => (
    <CardPerson key={person.id} person={person} />
  ));
  return <>{filtered}</>;
}

export function SearchPerson({ details }) {
  const [searchField, setSearchField] = useState("");

  const filteredPersons = details.filter((person) => {
    return person.nome.toLowerCase().includes(searchField.toLowerCase());
  });

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  function searchList() {
    return (
      <Scroll>
        <SearchPersonList filteredPersons={filteredPersons} />
      </Scroll>
    );
  }

  return (
    <>
      <div className="bg-white mt-4">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only"
            >
              Pesquisar
            </label>
            <div className="relative">
              <input
                className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-gray-500 focus:border-gray-500"
                placeholder="Pesquisar"
                onChange={handleChange}
              />
              <button
                type="submit"
                className="text-white absolute right-2.5 bottom-2.5 font-medium rounded-lg text-sm px-2"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <table className="table-auto flex flex-col flex-1 mt-4">
        <thead className="rounded-t-lg bg-[#5D7183] text-white p-3">
          <tr>
            <th>Usuário</th>
          </tr>
        </thead>
        <tbody className="rounded-b-lg overflow-y-scroll h-60 bg-[#F5F5F5] text-gray-800">
          {searchList()}
        </tbody>
      </table>
    </>
  );
}
