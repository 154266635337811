import { BrowserRouter, Route, Routes } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { Login } from "./pages/Login";
import { Notification } from "./pages/Notification";
import { CreateNotification } from "./pages/Notification/Create";

function PrivateRoute({ Item }) {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Item /> : <Login />;
}

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute Item={Notification} />} />
        <Route
          path="/notifications/create"
          element={<PrivateRoute Item={CreateNotification} />}
        />
      </Routes>
    </BrowserRouter>
  );
}
