import { AuthProvider } from "./contexts/auth";
import { FilterNotificationsProvider } from "./contexts/filterNotifications";
import { NotificationListProvider } from "./contexts/notificationList";
import { Router } from "./routes";

function App() {
  return (
    <AuthProvider>
      <NotificationListProvider>
        <FilterNotificationsProvider>
          <Router />
        </FilterNotificationsProvider>
      </NotificationListProvider>
    </AuthProvider>
  );
}

export default App;
