import axios from "axios";
import { useCookies } from "react-cookie";

let isRefreshing = false;
let failedRequestsQueue = [];

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.response.use(
  (response) => {
    console.log("🚀 ~ response", response);
    return response;
  },
  (error) => {
    console.log("🚀 ~ error:", error);
    // const { signOut } = useAuth();

    if (error.response.status === 401) {
      if (error.response.data?.code === "token.expired") {
        const [cookies, setCookie] = useCookies(["access_Token", "refresh_Token"]);

        const { refresh_Token } = cookies;
        const originalConfig = error.config;

        if (!isRefreshing) {
          isRefreshing = true;

          axios
            .post(`${process.env.REACT_APP_API_NOTIFICACAO}/auth/refresh-token`, {
              RefreshToken: refresh_Token,
            })
            .then((response) => {
              const { access_Token, refresh_Token } = response.data;

              setCookie("access_Token", access_Token, {
                path: "/",
                maxAge: 60 * 60 * 24 * 30,
              });
              setCookie("refresh_Token", refresh_Token, {
                path: "/",
                maxAge: 60 * 60 * 24 * 30,
              });

              api.defaults.headers.common["Authorization"] = `Bearer ${access_Token}`;

              failedRequestsQueue.forEach((request) => request.onSuccess(access_Token));
              failedRequestsQueue = [];
            })
            .catch((err) => {
              failedRequestsQueue.forEach((request) => request.onFailure(err));
              failedRequestsQueue = [];
            })
            .finally(() => {
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: (access_Token) => {
              originalConfig.headers.common["Authorization"] = `Bearer ${access_Token}`;

              resolve(api(originalConfig));
            },
            onFailure: (err) => {
              reject(err);
            },
          });
        });
      } else {
        // signOut();
      }
    }

    return Promise.reject(error);
  }
);

export default api;
