import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import api from "../../../services/api";
import { Footer, styles } from "./structurePdf";
import apiNotificationBasicAuth from "../../../helpers/basicAuthApiNotification";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const date = new Date();

export async function DownloadPDF(notification, usersToSendNotification) {
  const body = [];

  if (usersToSendNotification.length) {
    usersToSendNotification.map((user) => {
      return body.push([
        { text: user.id, fontSize: 9, margin: [0, 2, 0, 2] },
        { text: user.nome, fontSize: 9, margin: [0, 2, 0, 2] },
      ]);
    });
  }

  if (notification.users.length) {
    notification.users.map((user) => {
      return body.push([
        { text: user.value, fontSize: 9, margin: [0, 2, 0, 2] },
        { text: user.label, fontSize: 9, margin: [0, 2, 0, 2] },
      ]);
    });
  }

  const { data: statusNotification } = await api.get(
    `/status-notification/${notification.status}`,
    {
      auth: {
        username: apiNotificationBasicAuth.username,
        password: apiNotificationBasicAuth.password,
      },
    }
  );

  const { data: typeNotification } = await api.get(
    `/type-notification/${notification.type}`,
    {
      auth: {
        username: apiNotificationBasicAuth.username,
        password: apiNotificationBasicAuth.password,
      },
    }
  );

  const reportTitle = [
    {
      text: "Relatório de notificação agendada por usuários\n\n\n",
      style: "header",
    },
  ];

  const details = [
    {
      table: {
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            {
              text: "Notificação",
              style: "subheader",
            },
            {
              text: `Data de emissão: ${date.toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })} ${date.getHours()}:${date.getMinutes()} \n\n`,
              style: "subheader",
              alignment: "right",
              fontSize: 9,
            },
          ],
        ],
      },
      layout: "noBorders",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*", "*", "*", "*"],
        body: [
          [
            { text: "Título", style: "tableHeader" },
            {
              text: "Descrição",
              style: "tableHeader",
            },
            { text: "Status", style: "tableHeader" },
            {
              text: "Tipo (Alerta)",
              style: "tableHeader",
            },
          ],
          [
            {
              text: notification.title,
              fontSize: 9,
              margin: [0, 2, 0, 2],
            },
            {
              text: notification.description,
              fontSize: 9,
              margin: [0, 2, 0, 2],
            },
            {
              text: statusNotification.description,
              fontSize: 9,
              margin: [0, 2, 0, 2],
            },
            {
              text:
                typeNotification.description &&
                typeNotification.description + "\n\n\n",
              fontSize: 9,
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      },
      layout: "headerLineOnly",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            {
              text: "Usuários",
              style: "subheader",
            },
            {
              text: `Quantidade de usuários: ${body.length} \n\n`,
              style: "subheader",
              alignment: "right",
              fontSize: 9,
            },
          ],
        ],
      },
      layout: "noBorders",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            {
              text: "ID usuário",
              style: "tableHeader",
            },
            {
              text: "Username",
              style: "tableHeader",
            },
          ],
          ...body,
        ],
      },
      layout: "headerLineOnly",
    },
  ];

  const docDefinitions = {
    pageSize: "A4",
    pageMargins: [15, 50, 15, 40],
    styles: styles,
    header: [reportTitle],
    content: [details],
    footer: Footer,
  };

  pdfMake
    .createPdf(docDefinitions)
    .download(
      `${notification?.title.replace(/[^A-Z0-9]+/gi, "-").toLowerCase()}.pdf`
    );
}
