import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
export const styles = {
  header: {
    fontSize: 16,
    alignment: "center",
    bold: true,
    margin: [15, 20, 0, 45],
  },
  subheader: {
    fontSize: 14,
    bold: true,
    margin: [0, 10, 0, 5],
  },
  tableHeader: {
    bold: true,
    fontSize: 12,
    color: "black",
  },
};

export function Footer(currentPage, pageCount) {
  return [
    {
      text: currentPage + " / " + pageCount,
      alignment: "right",
      fontSize: 9,
      margin: [0, 10, 20, 0],
    },
  ];
}
