import { useContext } from "react";
import { FilterNotificationsContext } from "../contexts/filterNotifications";

const useFilterNotification = () => {
  const context = useContext(FilterNotificationsContext);

  return context;
};

export default useFilterNotification;
