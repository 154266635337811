import { useEffect } from "react";
import {
  CalendarIcon,
  ExclamationCircleIcon,
  FunnelIcon,
  BoltIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import ptBR from "date-fns/locale/pt-BR";
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import useNotificationList from "../../hooks/useNotificationList";
import useFilterNotification from "../../hooks/useFilterNotifications";

export function ModalFilterNotifications() {
  registerLocale("pt-br", ptBR);
  const cancelButtonRef = useRef(null);
  const [open, setOpen] = useState(true);
  const { alert, setAlert, automatic, setAutomatic } = useNotificationList();
  const { filter, submit, clearFilter } = useFilterNotification();
  const [filterFinalDate, setFilterFinalDate] = useState(
    filter.filterFinalDate ? new Date(filter.filterFinalDate) : new Date()
  );
  const [filterInitialDate, setFilterInitialDate] = useState(
    filter.filterInitialDate ? new Date(filter.filterInitialDate) : new Date()
  );

  useEffect(() => {
    setOpen(true);
  }, [open]);

  async function handleCleanFilter(event) {
    try {
      event.preventDefault();

      setOpen(false);
      setAlert();
      await clearFilter();
    } catch (error) {
      console.log("🚀 ~ error", error);
    }
  }

  async function handleSubmit(event) {
    try {
      event.preventDefault();

      if (!filterInitialDate || !filterFinalDate) {
        window.alert("Preencha todos os campos obrigatórios corretamente.");
        return;
      }

      await submit({ filterInitialDate, filterFinalDate, alert, automatic });
    } catch (error) {
      console.log("🚀 ~ error", error);
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="p-10">
                  <div className="flex justify-between">
                    <span>Filtrar</span>
                  </div>

                  <div className="mt-4">
                    <span>Período</span>
                    <div className="flex justify-between items-center">
                      <div className="relative">
                        <ReactDatePicker
                          selected={filterInitialDate}
                          onChange={(date) => {
                            date.setHours(0, 0, 0);
                            setFilterInitialDate(date);
                          }}
                          type="date"
                          locale="pt-br"
                          dateFormat="dd/MM/yyyy"
                          className="text-[#46484A] bg-gray-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2"
                        />

                        <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
                          <CalendarIcon height={20} />
                        </div>
                      </div>

                      <span>até</span>

                      <div className="relative">
                        <ReactDatePicker
                          selected={filterFinalDate}
                          onChange={(date) => setFilterFinalDate(date)}
                          type="date"
                          locale="pt-br"
                          dateFormat="dd/MM/yyyy"
                          className="text-[#46484A] bg-gray-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2"
                        />

                        <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
                          <CalendarIcon height={20} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <label
                      htmlFor="automatic-toggle"
                      className="flex relative items-center justify-between cursor-pointer"
                    >
                      <div className="flex items-center">
                        <BoltIcon
                          className="text-white rounded-full bg-[#336CD3] p-[1px]"
                          height={20}
                        />

                        <span className="ml-2 text-sm font-medium text-[#46484A]">
                          Notificações automáticas
                        </span>
                      </div>
                      <input
                        type="checkbox"
                        onClick={() => setAutomatic(!automatic)}
                        id="automatic-toggle"
                        className="sr-only peer"
                        defaultChecked={automatic}
                        value={automatic}
                      />
                      <div
                        className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-gray-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all after:bg-white after:right-[22px] ${
                          automatic && "peer-checked:bg-[#336CD3]"
                        }`}
                      ></div>
                    </label>
                  </div>

                  <div className="mt-4">
                    <label
                      htmlFor="alert-toggle"
                      className="flex relative items-center justify-between cursor-pointer"
                    >
                      <div className="flex items-center">
                        <ExclamationCircleIcon
                          className="text-white rounded-full bg-[#EC8F04]"
                          height={20}
                        />

                        <span className="ml-2 text-sm font-medium text-[#46484A]">
                          Exibir alertas
                        </span>
                      </div>
                      <input
                        type="checkbox"
                        onClick={() => setAlert(!alert)}
                        id="alert-toggle"
                        className="sr-only peer"
                        defaultChecked={alert}
                        value={alert}
                      />
                      <div
                        className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-gray-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all after:bg-white after:right-[22px] ${
                          alert && "peer-checked:bg-[#EC8F04]"
                        }`}
                      ></div>
                    </label>
                  </div>

                  <div className="bg-white mt-4 sm:flex justify-between">
                    <button
                      type="button"
                      className="border border-[#CF7777] bg-white hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                      onClick={handleCleanFilter}
                    >
                      <span className="text-[#CF7777]">Limpar</span>
                      <TrashIcon height={20} className="ml-2 text-[#CF7777]" />
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-slate-800 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-{#28343E} focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={handleSubmit}
                      ref={cancelButtonRef}
                    >
                      Aplicar
                      <FunnelIcon className="ml-2" height={20} />
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
