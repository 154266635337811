import {
  BoltIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
  FunnelIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { DialogProvider, useDialog } from "../../contexts/DialogContext";
import useAuth from "../../hooks/useAuth";
import useFilterNotification from "../../hooks/useFilterNotifications";
import useNotificationList from "../../hooks/useNotificationList";
import api from "../../services/api";
import { ModalFilterNotifications } from "../ModalFilterNotifications";
import { Pagination } from "../Pagination";
import { OpenPDF } from "../reports/notificationsByUser/openPdf";
import Scroll from "../Scroll";
import apiNotificationBasicAuth from "../../helpers/basicAuthApiNotification";

function CardNotification({ notification }) {
  let navigate = useNavigate();
  const { setDuplicateNotification, notificationList, setNotificationList } =
    useNotificationList();
  const formattedDate = new Date(
    notification.scheduledDateAt
  ).toLocaleDateString("pt-BR");
  const { openDialog, closeDialog } = useDialog();

  async function handleDuplicateNotification(notification) {
    const { _id, ...data } = notification;
    setDuplicateNotification(data);

    await navigate("/notifications/create");
  }

  async function handleEditNotification(notification) {
    setDuplicateNotification(notification);

    await navigate("/notifications/create");
  }

  return (
    <tr>
      <th
        scope="row"
        className="py-2 px-3 text-sm text-[#111111] whitespace-nowrap"
      >
        {notification.title}
      </th>
      <td className="py-2 px-3 flex justify-end">
        {notification.automatic && (
          <>
            <BoltIcon
              className="text-white rounded-full bg-[#336CD3] p-[1px] my-3 mx-[3px]"
              height={20}
              data-tooltip-id="automatic-tooltip"
              data-tooltip-content="Automático"
            />
            <Tooltip id="automatic-tooltip" />
          </>
        )}
        {notification.alert && (
          <>
            <ExclamationCircleIcon
              className="text-white rounded-full bg-[#EC8F04] my-3 mx-[3px]"
              height={20}
              data-tooltip-id="alert-tooltip"
              data-tooltip-content="Alerta"
            />
            <Tooltip id="alert-tooltip" />
          </>
        )}
      </td>
      <td className="py-2 px-3">
        {notification.status === "63d2b882bcad11b2f30e931b" && (
          <div className="flex justify-center items-center text-white bg-[#4F81DE] font-medium rounded-lg text-xs p-1 mr-2 mb-2">
            Agendada
          </div>
        )}

        {notification.status === "63d2ba8abcad11b2f30e931e" && (
          <div className="flex justify-center items-center text-white bg-[#CF7777] font-medium rounded-lg text-xs p-1 mr-2 mb-2">
            Cancelada
          </div>
        )}

        {notification.status === "63d2ba8ebcad11b2f30e9320" && (
          <div className="flex justify-center items-center text-white bg-[#5D9898] font-medium rounded-lg text-xs p-1 mr-2 mb-2">
            Enviada
          </div>
        )}
      </td>
      <td className="py-2 px-3 text-sm text-[#111111]">
        {formattedDate} {notification.scheduledHourAt}
      </td>
      <td className="py-2 px-3">
        {notification.status === "63d2ba8ebcad11b2f30e9320" && (
          <button
            type="button"
            className="text-[#969FA8] font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
            onClick={() => OpenPDF(notification)}
          >
            <DocumentTextIcon height={24} />
          </button>
        )}
      </td>
      <td className="py-2 px-3">
        <button
          type="button"
          className="text-[#4C5C6B] font-medium rounded-lg text-sm px-1 py-2.5 mr-2 mb-2 focus:outline-none"
          onClick={() => handleDuplicateNotification(notification)}
          data-tooltip-id="duplicate-tooltip"
          data-tooltip-content="Duplicar"
        >
          <DocumentDuplicateIcon height={24} />
        </button>
        <Tooltip id="duplicate-tooltip" />
        {/* id notification "enviada" - 63d2b882bcad11b2f30e931b */}  
        <button
          type="button"
          className="text-[#4C5C6B] font-medium rounded-lg text-sm px-1 py-2.5 mr-2 mb-2 focus:outline-none"
          onClick={() => handleEditNotification(notification)}
          data-tooltip-id="edit-tooltip"
          data-tooltip-content="Editar"
        >
          <PencilSquareIcon height={24} />
        </button>
        <Tooltip id="edit-tooltip" />

        <button
          type="button"
          className="text-[#4C5C6B] font-medium rounded-lg text-sm px-1 py-2.5 mr-2 mb-2 focus:outline-none"
          onClick={() => {
            openDialog({
              title: "Confirmação de exclusão",
              description:
                "Você irá excluir a notificação agendada. Deseja mesmo excluir?",
              buttons: [
                {
                  label: "Cancelar",
                  bg: "bg-slate-200",
                  color: "text-gray",
                  onClick: () => {
                    closeDialog();
                  },
                },
                {
                  label: "Excluir",
                  bg: "bg-red-600",
                  color: "text-white",
                  onClick: () => {
                    api
                      .delete(`/notifications/${notification._id}`, {
                        auth: {
                          username: apiNotificationBasicAuth.username,
                          password: apiNotificationBasicAuth.password,
                        },
                      })
                      .then((r) => {
                        notificationList.data.notifications =
                          setNotificationList({
                            ...notificationList,
                            data: {
                              ...notificationList.data,
                              notifications:
                                notificationList.data.notifications.filter(
                                  (el) => el._id !== notification._id
                                ),
                            },
                          });

                        closeDialog();
                      })
                      .catch((err) => console.error(err));
                  },
                },
              ],
            });
          }}
          data-tooltip-id="remove-tooltip"
          data-tooltip-content="Excluir"
        >
          <TrashIcon height={24} />
        </button>
        <Tooltip id="remove-tooltip" />
      </td>
    </tr>
  );
}

function SearchNotificationList({ filteredNotifications }) {
  const {
    setCurrentPageNumber,
    setSortedDocuments,
    searchField,
    filterInitialDate,
    filterFinalDate,
    alert,
    automatic,
    handleNotificationList,
  } = useNotificationList();
  const { filter } = useFilterNotification();
  const [sortNotificationsByScheduleDate, setSortNotificationsByScheduleDate] =
    useState(true);

  const filtered = filteredNotifications.notifications.map((notification) => (
    <CardNotification key={notification._id} notification={notification} />
  ));

  async function handleSortedNotifications() {
    setSortNotificationsByScheduleDate(!sortNotificationsByScheduleDate);

    const sortedDocuments = sortNotificationsByScheduleDate ? 1 : -1;
    setCurrentPageNumber(1);
    setSortedDocuments(sortedDocuments);

    await handleNotificationList(
      0,
      sortedDocuments,
      searchField,
      filter.filterInitialDate ? filter.filterInitialDate : filterInitialDate,
      filter.filterFinalDate ? filter.filterFinalDate : filterFinalDate,
      filter.alert ? filter.alert : alert,
      filter.automatic ? filter.automatic : automatic
    );
  }

  return (
    <DialogProvider>
      <table className="bg-white w-full text-sm text-left text-gray-500">
        <thead className="text-xs border-b-2 text-gray-700 uppercase">
          <tr>
            <th scope="col" className="py-3 px-3">
              Título da notificação
            </th>
            <th scope="col" className="py-3 px-3"></th>
            <th scope="col" className="py-3 px-3">
              Status
            </th>
            <th
              scope="col"
              className="flex py-3 px-3"
              onClick={handleSortedNotifications}
            >
              Agendado para
              {sortNotificationsByScheduleDate ? (
                <ChevronUpIcon className="ml-1" height={16} />
              ) : (
                <ChevronDownIcon className="ml-1" height={16} />
              )}
            </th>
            <th scope="col" className="py-3 px-3">
              Relatório
            </th>
            <th scope="col" className="py-3 px-3">
              Ações
            </th>
          </tr>
        </thead>
        <tbody className="text-xs">{filtered}</tbody>
      </table>
    </DialogProvider>
  );
}

export function SearchNotification({ filter: filtered }) {
  const {
    alert,
    automatic,
    setAlert,
    searchField,
    setSearchField,
    sortedDocuments,
    filterFinalDate,
    notificationList,
    filterInitialDate,
    notificationsLength,
    setCurrentPageNumber,
    handleNotificationList,
    setDuplicateNotification,
  } = useNotificationList();
  const { filter } = useFilterNotification();
  const { signOut } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [cookies] = useCookies(["access_Token"]);
  const { access_Token } = cookies;
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const userToken = localStorage.getItem("user");

    if (userToken) {
      let decodedToken = jwtDecode(access_Token);
      let currentDate = new Date();

      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        signOut();
      }
    }
  }, []);

  useEffect(() => {
    setCurrentPageNumber(1);

    if (filtered.hasOwnProperty("filterInitialDate")) {
      setAlert(filtered.alert);

      handleNotificationList(
        0,
        sortedDocuments,
        searchField,
        filtered.filterInitialDate,
        filtered.filterFinalDate,
        filtered.alert,
        filtered.automatic
      );
    } else {
      handleNotificationList(
        0,
        sortedDocuments,
        searchField,
        filterInitialDate,
        filterFinalDate,
        alert,
        automatic
      );
    }
  }, [searchField, filtered]);

  async function handleCurrentPage(pageNumber) {
    let skipPage;
    setPage(pageNumber);
    setCurrentPageNumber(pageNumber);

    if (pageNumber === 1) {
      skipPage = 0;
    } else {
      skipPage = (pageNumber - 1) * 10;
    }

    await handleNotificationList(
      skipPage,
      sortedDocuments,
      searchField,
      filter.filterInitialDate ? filter.filterInitialDate : filterInitialDate,
      filter.filterFinalDate ? filter.filterFinalDate : filterFinalDate,
      filter.alert ? filter.alert : alert,
      filter.automatic ? filter.automatic : automatic
    );
  }

  function handleChangeSearch(e) {
    setSearchField(e.target.value);
  }

  function searchList() {
    return (
      <Scroll>
        <SearchNotificationList filteredNotifications={notificationList.data} />
      </Scroll>
    );
  }

  function handleModalOpen() {
    setShowModal(!showModal);
  }

  return (
    <>
      <div className="flex justify-between">
        <div className="bg-white">
          <div className="sm:flex sm:items-start">
            <div className="text-center sm:text-left">
              <div className="relative">
                <input
                  className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-[#3A4651] focus:ring-gray-500 focus:border-gray-500"
                  placeholder="Pesquisar"
                  onChange={handleChangeSearch}
                  value={searchField}
                />
                <button
                  type="submit"
                  className="text-white absolute right-2.5 bottom-1 font-medium rounded-lg text-sm px-4 py-2"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>

            <div className="bg-gray-50 px-4 text-center sm:px-6">
              <button
                type="button"
                className="inline-flex justify-center rounded-md border border-[#3A4651] py-3 px-4 text-sm font-medium text-[#3A4651] shadow-sm hover:bg-{#28343E} focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={handleModalOpen}
              >
                {showModal && <ModalFilterNotifications />}
                Filtrar
                <FunnelIcon className="ml-2" height={20} />
              </button>
            </div>
          </div>
        </div>

        <div className="flex">
          <button
            className="border border-transparent flex items-center p-3 bg-gray-800 text-white text-sm uppercase font-medium rounded hover:bg-gray-500 focus:outline-none focus:bg-gray-500"
            onClick={async () => {
              setDuplicateNotification(undefined);

              await navigate("/notifications/create");
            }}
          >
            <span>Criar notificação</span>
            <PlusIcon height={20} />
          </button>
        </div>
      </div>

      <div className="overflow-x-auto relative mt-6 shadow rounded">
        {searchList()}
      </div>

      <Pagination
        registersPerPage={10}
        onPageChange={handleCurrentPage}
        totalCountOfRegisters={notificationsLength}
        currentPage={page}
      />
    </>
  );
}
