import axios from "axios";
import jwtDecode from "jwt-decode";
import { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import api from "../../services/api";

export const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const isAuthenticated = !!user;
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(["access_Token", "refresh_Token"]);
  const { access_Token } = cookies;

  useEffect(() => {
    const userToken = localStorage.getItem("user");

    if (userToken) {
      let user = JSON.parse(userToken);
      setUser(user);
      let decodedToken = jwtDecode(access_Token);
      let currentDate = new Date();

      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        signOut();
      }
    }
  }, []);

  async function signIn(username, password) {
    const { data: response } = await axios
      .post(`${process.env.REACT_APP_API_NOTIFICACAO}/auth/access-token`, {
        username,
        password,
      })
      .catch(async (error) => {
        console.log("🚀 ~ error:", error);
        // signOut();

        if (error.response.status === 401) {
          // Se a resposta é 401, atualiza o token de acesso usando o token de refresh
          const newAccessToken = await refreshAccessToken();
          setAccessToken(newAccessToken);

          // Tenta a solicitação novamente com o novo token de acesso
          try {
            const response = await axios.get("/api/data", {
              headers: {
                Authorization: `Bearer ${newAccessToken}`,
              },
            });
          } catch (error) {
            console.error(error);
          }
        }
      });
    const { access_Token, refresh_Token } = response;

    setCookie("access_Token", access_Token, {
      path: "/",
      maxAge: 60 * 60 * 24 * 30,
    });
    setCookie("refresh_Token", refresh_Token, {
      path: "/",
      maxAge: 60 * 60 * 24 * 30,
    });

    setUser({ username });
    setAccessToken(access_Token);
    setRefreshToken(refresh_Token);
    window.location.href = "/";
    localStorage.setItem("user", JSON.stringify({ username }));
    api.defaults.headers.common["Authorization"] = `Bearer ${access_Token}`;
    return;
  }

  const refreshAccessToken = async () => {
    try {
      const response = await axios.post("/auth/refresh-token", {
        refreshToken: refreshToken,
      });

      return response.data.accessToken;
    } catch (error) {
      console.error(error);
      // Se o token de refresh estiver expirado ou inválido, desconecta o usuário e redireciona para a página de login
      // signOut();
    }
  };

  async function signOut() {
    try {
      setAccessToken(null);
      setRefreshToken(null);
      // Redireciona para a página de login
      window.location.href = "/login";

      setUser();
      removeCookie("access_Token");
      removeCookie("refresh_Token");
      localStorage.removeItem("user");
    } catch (error) {
      console.log("🚀 ~ error", error);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        signIn,
        signOut,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
