import { Dialog, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  ClockIcon,
  CloudArrowDownIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { Fragment, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import api from "../../services/api";
import { DownloadPDF } from "../reports/notificationsByUser/downloadPdf";
import { SearchPerson } from "../SearchPerson";

const apiNotificationAuth = {
  username: process.env.REACT_APP_API_NOTIFICATION_USERNAME,
  password: process.env.REACT_APP_API_NOTIFICATION_PASSWORD,
};

export function Modal({ dataCreateNotification }) {
  let navigate = useNavigate();
  const { signOut } = useAuth();
  const cancelButtonRef = useRef(null);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(true);
  const [plans, setPlans] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [cookies] = useCookies(["access_Token"]);
  const { access_Token } = cookies;
  const [usersToSendNotification, setUsersToSendNotification] = useState([]);

  useEffect(() => {
    setData(dataCreateNotification);

    setProfiles([...dataCreateNotification.profiles.map((profileUnit) => profileUnit.id)]);
    setPlans([...dataCreateNotification.plans.map((profileUnit) => profileUnit.id)]);
  }, [dataCreateNotification]);

  useEffect(() => {
    if (access_Token && plans.length && profiles.length) {
      fetch(`${process.env.REACT_APP_API_NOTIFICACAO}/usuario/listar`, {
        method: "post",
        headers: new Headers({
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_Token}`,
        }),
        body: JSON.stringify({
          plano: plans,
          perfil: profiles,
        }),
      })
        .then((response) => response.json())
        .then((data) => setUsersToSendNotification(data))
        .catch((error) => {
          console.log("🚀 ~ error:", error);
          // signOut();
        });
    }
  }, [access_Token, profiles, plans]);

  useEffect(() => {
    if (data.users?.length) {
      data.users.map((user) => {
        if (usersToSendNotification.length) {
          const userAlreadyExists = usersToSendNotification.find(
            (userUnit) => userUnit.id === user.value
          );

          if (userAlreadyExists) {
            data.users.splice(data.users.indexOf(userAlreadyExists.id), 1);
          }
        }

        return data.users;
      });
    }
  }, [data.users, usersToSendNotification]);

  async function handleSubmit(e) {
    try {
      e.preventDefault();

      const profileList = new Array(data.profiles.map((profile) => profile.id));
      const planList = new Array(data.plans.map((plan) => plan.id));

      const requestMethod = data._id ? "patch" : "post";

      const response = await api[requestMethod](
        `/notifications${data._id ? `/${data._id}` : ""}`,
        {
          title: data.title,
          description: data.description,
          redirectTo: data.redirectTo,
          scheduledDateAt: data.scheduledDateAt,
          scheduledHourAt: format(data.scheduledHourAt, "HH:mm"),
          alert: data.alert,
          type: data.type,
          expirationDate: data.expirationDate,
          users: data.users,
          profiles: profileList[0],
          plans: planList[0],
          status: data.status,
          automatic: false,
        },
        {
          auth: apiNotificationAuth,
        }
      ).catch((error) => {
        console.log("🚀 ~ error:", error);
        // signOut();
      });

      setOpen(false);
      navigate("/");

      return response;
    } catch (error) {
      console.log("🚀 ~ error", error);
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="p-10">
                  <div className="bg-white">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Salvar notificação
                        </Dialog.Title>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white p-5 border rounded mt-4">
                    <div className="sm:flex sm:items-start">
                      <div className="flex items-center justify-center mt-3 text-center sm:mt-0 sm:text-left">
                        <DocumentTextIcon height={24} />
                        <p className="ml-3 text-sm font-medium text-gray-500">
                          Conheça o percurso realizado pela sua contribuição
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-around bg-white p-8 border rounded mt-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 flex flex-col items-center w-full text-center sm:mt-0 sm:text-left">
                        <p className="text-3xl text-gray-500">{usersToSendNotification.length}</p>
                        <p className="text-lg text-gray-500">Destinatários</p>
                      </div>
                    </div>

                    <div className="border border-gray-500"></div>

                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 flex flex-col items-start w-full text-center sm:mt-0 sm:text-left">
                        <p className="text-xs text-gray-500 font-medium">Agendado para</p>
                        <div className="flex justify-center items-center">
                          <CalendarIcon height={16} className="mr-2" />
                          <p className="text-sm text-gray-500">
                            {data.scheduledDateAt &&
                              data.scheduledDateAt.toLocaleDateString("pt-BR")}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <ClockIcon height={16} className="mr-2" />
                          <p className="text-sm text-gray-500">
                            {data.scheduledHourAt && format(data.scheduledHourAt, "HH:mm")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {usersToSendNotification && <SearchPerson details={usersToSendNotification} />}

                  <div className="bg-white mt-4 sm:flex justify-evenly">
                    <button
                      type="button"
                      className="border-2 border-gray-800 bg-white hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                      onClick={() => DownloadPDF(data, usersToSendNotification)}
                    >
                      <span>Download</span>
                      <CloudArrowDownIcon height={20} className="ml-2" />
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-slate-800 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-{#28343E} focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={handleSubmit}
                      ref={cancelButtonRef}
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
