import { SearchNotification } from "../../components/SearchNotification";
import useFilterNotification from "../../hooks/useFilterNotifications";
import useNotificationList from "../../hooks/useNotificationList";

export function Notification() {
  const { filter } = useFilterNotification();
  const { notificationList } = useNotificationList();

  return (
    <>
      <main className="min-h-full">
        <div className="mx-auto max-w-4xl py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            <div className="h-96">
              {notificationList.data && <SearchNotification filter={filter} />}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
