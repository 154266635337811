import { Dialog, Transition } from "@headlessui/react";
import React, { createContext, Fragment, useContext, useMemo, useState } from "react";

// interface DialogOptions {
//   title: string;
//   description: string;
//   buttons: {
//     onClick: () => void;
//     label: string;
//     color: string;
//   }[];
// }

// interface DialogContextProps {
//   openDialog: (options: DialogOptions) => void;
//   closeDialog: () => void;
// }

const DialogContext = createContext({});

const initialOptions = {
  title: "",
  description: "",
  buttons: [],
};

const DialogProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState(initialOptions);
  const { title, description, buttons } = options;

  // Open dialog fn
  const openDialog = (options) => {
    setOptions(options);
    setIsOpen(true);
  };

  // Close dialog fn
  const closeDialog = () => {
    setIsOpen(false);
    setOptions(initialOptions);
  };

  return (
    <DialogContext.Provider
      value={useMemo(() => ({ openDialog, closeDialog }), [openDialog, closeDialog])}
    >
      {children}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeDialog}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{description}</p>
                  </div>

                  <div className="mt-4 flex justify-end flex-col md:flex-row">
                    {/* <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeDialog}
                    >
                      Got it, thanks!
                    </button> */}

                    {buttons?.map((button, i) => (
                      <button
                        key={i}
                        type="button"
                        className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 m-1 md:my-0 ${button.bg} ${button.color}`}
                        // style={{ color: button.color }}
                        onClick={button.onClick}
                      >
                        {button.label}
                      </button>
                    ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </DialogContext.Provider>
  );
};

const useDialog = () => {
  const context = useContext(DialogContext);

  if (!context) {
    throw new Error("useDialog must be used within a DialogProvider");
  }

  return context;
};

export { DialogProvider, useDialog };
