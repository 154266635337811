import {
  CheckCircleIcon,
  PencilSquareIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import { LinkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactSelect from "react-select";
import { Modal } from "../../../components/Modal";
import apiNotificationBasicAuth from "../../../helpers/basicAuthApiNotification";
import useAuth from "../../../hooks/useAuth";
import useNotificationList from "../../../hooks/useNotificationList";
import api from "../../../services/api";
import "./styles.css";

export function CreateNotification() {
  registerLocale("pt-br", ptBR);
  const { duplicateNotification } = useNotificationList();
  const { signOut } = useAuth();
  const currentDate = new Date();
  const [type, setType] = useState(
    duplicateNotification ? duplicateNotification.type : ""
  );
  const [plans, setPlans] = useState(
    duplicateNotification ? duplicateNotification.plans : []
  );
  const [profiles, setProfiles] = useState(
    duplicateNotification ? duplicateNotification.profiles : []
  );
  const [users, setUsers] = useState(
    duplicateNotification ? duplicateNotification.users : []
  );
  const [alert, setAlert] = useState(
    duplicateNotification ? duplicateNotification.alert : false
  );
  const [planList, setPlanList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [title, setTitle] = useState(
    duplicateNotification ? duplicateNotification.title : ""
  );
  const [cookies] = useCookies(["access_Token", "refresh_Token"]);
  const { access_Token } = cookies;
  const [scheduledDateAt, setScheduledDateAt] = useState(
    duplicateNotification
      ? new Date(duplicateNotification.scheduledDateAt)
      : currentDate
  );

  const [scheduledHourAt, setScheduledHourAt] = useState(
    duplicateNotification
      ? new Date(
          `${format(new Date(scheduledDateAt), "yyyy-MM-dd")}T${
            duplicateNotification.scheduledHourAt
          }:00.000-03:00`
        )
      : currentDate
  );

  const [expirationDate, setExpirationDate] = useState(
    duplicateNotification
      ? new Date(duplicateNotification.expirationDate)
      : currentDate
  );

  const [isSelectedAllPlans, setIsSelectedAllPlans] = useState(true);
  const [lengthTextNotification, setLengthTextNotification] = useState(0);
  const [isSelectedAllProfiles, setIsSelectedAllProfiles] = useState(true);
  const [dataCreateNotification, setDataCreateNotification] = useState({});
  const [redirectTo, setRedirectTo] = useState(
    duplicateNotification ? duplicateNotification.redirectTo : ""
  );
  const [description, setDescription] = useState(
    duplicateNotification ? duplicateNotification.description : ""
  );

  useEffect(() => {
    setScheduledDateAt(
      new Date(
        scheduledDateAt.setHours(
          scheduledHourAt.getHours(),
          scheduledHourAt.getMinutes()
        )
      )
    );
  }, [scheduledHourAt]);

  useEffect(() => {
    const userToken = localStorage.getItem("user");

    if (userToken) {
      let decodedToken = jwtDecode(access_Token);
      let currentDate = new Date();

      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        signOut();
      }
    }

    axios
      .post(
        `${process.env.REACT_APP_API_NOTIFICACAO}/usuario/listar`,
        {
          plano: [],
          perfil: [],
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${access_Token}`,
          },
        }
      )
      .then((response) =>
        setUserOptions([
          ...response.data.map((dataUnit) => ({
            value: dataUnit.id,
            label: dataUnit.nome,
          })),
        ])
      )
      .catch((error) => {
        console.log("🚀 ~ error:", error);
      });
  }, []);

  useEffect(() => {
    if (access_Token) {
      fetch(`${process.env.REACT_APP_API_NOTIFICACAO}/perfil/listar`, {
        method: "get",
        headers: new Headers({
          accept: "application/json",
          Authorization: `Bearer ${access_Token}`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setProfileList([
            ...data.map((dataUnit) => ({
              ...dataUnit,
              isSelected: duplicateNotification
                ? duplicateNotification.profiles.includes(dataUnit.id)
                : false,
            })),
          ]);
        })
        .catch((error) => {
          console.log("🚀 ~ error:", error);
          // signOut();
        });

      fetch(`${process.env.REACT_APP_API_NOTIFICACAO}/plano/listar`, {
        method: "get",
        headers: new Headers({
          accept: "application/json",
          Authorization: `Bearer ${access_Token}`,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setPlanList([
            ...data.map((dataUnit) => ({
              ...dataUnit,
              isSelected: duplicateNotification
                ? duplicateNotification.plans.includes(dataUnit.id)
                : false,
            })),
          ]);
        })
        .catch((error) => {
          console.log("🚀 ~ error:", error);
          // signOut();
        });
    }
  }, [access_Token]);

  useEffect(() => {
    if (!alert) {
      setType("");
    }
  }, [alert]);

  useEffect(() => {
    setProfiles(profileList.filter((profile) => profile.isSelected));
    setPlans(planList.filter((plan) => plan.isSelected));
  }, [profileList, planList]);

  // Profile List Options
  function handleSelectAllProfiles() {
    setIsSelectedAllProfiles(!isSelectedAllProfiles);

    if (isSelectedAllProfiles) {
      setProfileList([
        ...profileList.map((profile) => ({
          ...profile,
          isSelected: true,
        })),
      ]);
    } else {
      setProfileList([
        ...profileList.map((profile) => ({
          ...profile,
          isSelected: false,
        })),
      ]);
    }
  }
  function ProfileListComponent(options) {
    return (
      <li>
        <input type="checkbox" id={options.id} value={options.id} />
        <label
          htmlFor={options.id}
          className={`flex justify-center items-center focus:outline-none font-medium rounded-full text-xs p-2 mr-2 mb-2 max-h-7
          ${
            options.isSelected === true
              ? "text-white bg-[#3A4651]"
              : "text-[#5D7183] bg-[#F2F8F8]"
          }`}
          onClick={handleProfileList}
        >
          {options.description}
        </label>
      </li>
    );
  }
  function handleProfileList(e) {
    e.preventDefault();

    setProfileList([
      ...profileList.map((profile) =>
        profile.descricao === e.target.innerText
          ? { ...profile, isSelected: !profile.isSelected }
          : profile
      ),
    ]);
  }

  // Plan List Options
  function handleSelectAllPlans() {
    setIsSelectedAllPlans(!isSelectedAllPlans);

    if (isSelectedAllPlans) {
      setPlanList([
        ...planList.map((plan) => ({
          ...plan,
          isSelected: true,
        })),
      ]);
    } else {
      setPlanList([
        ...planList.map((plan) => ({
          ...plan,
          isSelected: false,
        })),
      ]);
    }
  }
  function PlanListComponent(options) {
    return (
      <li>
        <input type="checkbox" id={options.id} value={options.id} />
        <label
          htmlFor={options.id}
          className={`flex justify-center items-center focus:outline-none font-medium rounded-full text-xs p-2 mr-2 mb-2 max-h-7
          ${
            options.isSelected === true
              ? "text-white bg-[#3A4651]"
              : "text-[#5D7183] bg-[#F2F8F8]"
          }`}
          onClick={handlePlanList}
        >
          {options.description}
        </label>
      </li>
    );
  }
  function handlePlanList(e) {
    e.preventDefault();

    setPlanList([
      ...planList.map((plan) =>
        plan.descricao === e.target.innerText
          ? { ...plan, isSelected: !plan.isSelected }
          : plan
      ),
    ]);
  }

  const filteredUsers = userOptions.filter((user) => {
    return user.label.toLowerCase().includes(searchField.toLowerCase());
  });

  filteredUsers.splice(10);

  const handleInputChange = (inputValue) => {
    setSearchField(inputValue);
    return inputValue;
  };

  const handleChange = (optionsValue) => {
    setUsers(optionsValue);
    return optionsValue;
  };

  async function handleType(id) {
    const { data: typeData } = await api
      .get(`/type-notification/${id}`, {
        auth: {
          username: apiNotificationBasicAuth.username,
          password: apiNotificationBasicAuth.password,
        },
      })
      .catch((error) => {
        console.log("🚀 ~ error:", error);
        // signOut();
      });

    if (typeData._id) {
      setType(typeData._id);
    } else {
      setType('63d2c25a333d81b9bf1d2127')
    }
  }

  function handleValidate() {
    const hasSentStatus = duplicateNotification?.status === '63d2ba8ebcad11b2f30e9320';
    const scheduledDateAtIsValid = new Date(scheduledDateAt) > new Date();
    const expirationDateIsValid = new Date(expirationDate) > new Date();

    if (!title || !description) {
      if (hasSentStatus) {
        window.alert("Preencha todos os campos obrigatórios corretamente.");
        return;
      } else if (!scheduledDateAtIsValid || !scheduledHourAt) {
        window.alert("Preencha todos os campos obrigatórios corretamente.");
        return;
      }
    }

    if (alert === true && (!expirationDateIsValid || !type)) {
      window.alert(
        "Alerta está ativo, informe uma data de expiração válida."
      );
      return;
    }

    setDataCreateNotification({
      ...(duplicateNotification?._id && { _id: duplicateNotification._id }),
      title,
      description,
      redirectTo,
      scheduledDateAt,
      scheduledHourAt,
      alert,
      type,
      expirationDate,
      users,
      profiles,
      plans,
      status: duplicateNotification?.status || "63d2b882bcad11b2f30e931b",
    });

    setShowModal(!showModal);

    return dataCreateNotification;
  }

  return (
    <>
      <div className="bg-white">
        <div className="mx-auto max-w-2xl sm:px-6">
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form method="POST" onSubmit={handleValidate}>
                <div className="shadow sm:overflow-hidden sm:rounded-md">
                  <div className="space-y-6 bg-white px-4 py-5 sm:p-24">
                    {/* Título da notificação */}
                    <div>
                      <div className="relative mt-1 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="title"
                          id="title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          className="block py-2.5 px-0 w-full text-2xl font-bold tracking-tighter text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                          placeholder="Título da notificação"
                        />

                        <div className="absolute inset-y-0 right-0 flex items-center">
                          <PencilSquareIcon
                            height={24}
                            className="rounded-md border-transparent bg-transparent mr-3 text-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Descrição da notificação */}
                    <div className="relative">
                      <label
                        htmlFor="notification_text"
                        className="block text-sm font-black text-[#46484A]"
                      >
                        Descrição da notificação
                      </label>

                      <textarea
                        id="notification_text"
                        name="notification_text"
                        value={description}
                        onChange={(e) => {
                          setLengthTextNotification(e.target.value.length);
                          setDescription(e.target.value);
                        }}
                        rows={3}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm text-[#46484A]"
                        placeholder="Texto da notificação"
                        maxLength="180"
                      />

                      <span className="text-xs text-[#A1A6AB] absolute bottom-1 right-2">
                        {lengthTextNotification}/180
                      </span>
                    </div>

                    {/* Redirecionar para */}
                    <div>
                      <label
                        htmlFor="redirect_to"
                        className="block text-sm font-black text-[#46484A]"
                      >
                        Redirecionar para
                      </label>

                      <div className="relative mt-1 ">
                        <textarea
                          id="redirect_to"
                          name="redirect_to"
                          value={redirectTo}
                          onChange={(e) => setRedirectTo(e.target.value)}
                          rows={2}
                          className={`block w-full rounded-md ${
                            redirectTo &&
                            redirectTo.length > 0 &&
                            !redirectTo.startsWith("https://")
                              ? "border-red-300"
                              : "border-gray-300"
                          }  shadow-sm ${
                            redirectTo &&
                            redirectTo.length > 0 &&
                            !redirectTo.startsWith("https://")
                              ? "focus:border-red-500 focus:ring-red-500"
                              : "focus:border-gray-500 focus:ring-gray-500"
                          } sm:text-sm`}
                          placeholder="https://www.capef.com.br/site/noticias/"
                        />

                        {redirectTo &&
                          redirectTo.length > 0 &&
                          !redirectTo.startsWith("https://") && (
                            <p className="mt-2 text-red-600 text-sm">
                              Informe uma URL válida
                            </p>
                          )}

                        <div className="absolute mr-3 inset-y-0 right-0 flex items-center">
                          <LinkIcon
                            height={24}
                            className="rounded-md border-transparent bg-transparent mr-3 text-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                          />
                        </div>
                      </div>
                    </div>

                    {/* Agendar para */}
                    <div className="grid grid-cols-3 gap-16">
                      <div className="relative">
                        <div className="flex absolute inset-y-0 left-0 items-center pointer-events-none">
                          <span className="text-sm text-[#46484A] align-middle">
                            Agendar para
                          </span>
                        </div>
                      </div>

                      <div className="relative w-32">
                        <DatePicker
                          selected={scheduledDateAt}
                          onChange={(date) => setScheduledDateAt(date)}
                          type="date"
                          locale="pt-br"
                          dateFormat="dd/MM/yyyy"
                          className="text-[#46484A] bg-gray-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5"
                        />

                        <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
                          <CalendarIcon height={20} />
                        </div>
                      </div>

                      <div className="relative">
                        <DatePicker
                          selected={scheduledHourAt}
                          onChange={(date) => setScheduledHourAt(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={30}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                          className="text-[#46484A] bg-gray-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5"
                        />
                      </div>
                    </div>

                    {/* Exibir como alerta */}
                    <div>
                      <label
                        htmlFor="default-toggle"
                        className="flex relative items-center justify-between cursor-pointer"
                      >
                        <span className="text-sm text-[#46484A] font-medium">
                          Exibir como alerta
                        </span>
                        <input
                          type="checkbox"
                          value={alert}
                          onClick={() => setAlert(!alert)}
                          id="default-toggle"
                          className="sr-only peer"
                          defaultChecked={alert}
                        />
                        <div
                          className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-gray-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:right-[22px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${
                            alert && "peer-checked:bg-[#EC8F04]"
                          }`}
                        ></div>
                      </label>
                    </div>

                    {/* Tipo da notificação */}
                    <div className="sm:flex justify-between cursor-pointer grid grid-cols-2 gap-2">
                      <button
                        type="button"
                        className={`flex flex-col items-center relative text-white focus:outline-none focus:ring-2 focus:ring-blue-300 font-medium rounded-full text-sm text-center ${
                          !alert && "opacity-50 cursor-not-allowed"
                        } ${
                          type === "63d2c23c333d81b9bf1d211f" &&
                          "ring-2 ring-blue-300"
                        }`}
                        disabled={!alert}
                        onClick={() => handleType("63d2c23c333d81b9bf1d211f")}
                      >
                        <div className=" flex items-center justify-center h-14 w-14 rounded-full p-1 flex-shrink-0 bg-[#4F81DE]">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M20 5.49997C20 3.56697 18.433 1.99997 16.5 1.99997L16.2914 2.00183C14.5606 2.06311 13.0329 3.26341 12 5.17697L11.8644 4.93412C10.7953 3.09171 9.2384 1.96953 7.48258 2.00012C5.567 1.99997 4 3.56697 4 5.49997C4 6.03677 4.12085 6.54534 4.33682 6.99997H4C2.89543 6.99997 2 7.8954 2 8.99997V11C2 12.1045 2.89543 13 4 13V19L4.00509 19.1762C4.09634 20.751 5.40232 22 7 22H12H17L17.1763 21.9949C18.7511 21.9036 20 20.5977 20 19V13C21.1046 13 22 12.1045 22 11V8.99997C22 7.8954 21.1046 6.99997 20 6.99997H19.6632C19.8792 6.54534 20 6.03677 20 5.49997ZM16.5 6.99997C17.3284 6.99997 18 6.3284 18 5.49997C18 4.67154 17.3284 3.99997 16.4826 3.99982C15.3028 3.97926 14.0978 5.09604 13.357 6.99997H16.5ZM10.6429 6.99997C9.90173 5.09605 8.69426 3.97929 7.5 3.99997C6.67157 3.99997 6 4.67154 6 5.49997C6 6.27967 6.59489 6.92042 7.35554 6.9931L7.5 6.99997H10.6429ZM7.5 8.99997H4V11H5H11V8.99997H7.5ZM6 19V13H11V20H7L6.88338 19.9932C6.38604 19.9355 6 19.5128 6 19ZM17 20H13V13H18V19L17.9933 19.1166C17.9355 19.6139 17.5128 20 17 20ZM20 11H19H13V8.99997H16.5H20V11Z"
                              fill="white"
                            />
                          </svg>
                        </div>

                        {type === "63d2c23c333d81b9bf1d211f" && (
                          <span className="top-0 left-10 absolute w-3.5 h-3.5 text-blue-300 bg-white rounded-full">
                            <CheckCircleIcon />
                          </span>
                        )}
                      </button>
                      <button
                        type="button"
                        className={`flex flex-col items-center relative text-white focus:outline-none focus:ring-2 focus:ring-green-300 font-medium rounded-full text-sm text-center ${
                          !alert && "opacity-50 cursor-not-allowed"
                        } ${
                          type === "63d2c248333d81b9bf1d2121" &&
                          "ring-2 ring-green-300"
                        }`}
                        disabled={!alert}
                        onClick={(e) => handleType("63d2c248333d81b9bf1d2121")}
                      >
                        <div className=" flex items-center justify-center h-14 w-14 rounded-full p-1 flex-shrink-0 bg-[#86C1C1]">
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16.7933 2.88338C16.7356 2.38604 16.3129 2 15.8 2C15.2478 2 14.8 2.44772 14.8 3V4H8.80005V3L8.79332 2.88338C8.73556 2.38604 8.31288 2 7.80005 2C7.24776 2 6.80005 2.44772 6.80005 3V4H5.80005L5.62378 4.00509C4.04897 4.09634 2.80005 5.40232 2.80005 7V11V19L2.80514 19.1763C2.89639 20.7511 4.20237 22 5.80005 22H12.595L12.7117 21.9933C13.209 21.9355 13.595 21.5128 13.595 21C13.595 20.4477 13.1473 20 12.595 20H5.80005L5.68343 19.9933C5.18609 19.9355 4.80005 19.5128 4.80005 19V12H19.8C19.8395 12 19.8784 11.9977 19.9167 11.9933C19.9454 11.9899 19.9737 11.9854 20.0016 11.9797C20.4573 11.8864 20.8 11.4832 20.8 11V7L20.795 6.82373C20.7037 5.24892 19.3977 4 17.8 4H16.8V3L16.7933 2.88338ZM18.8 10V7C18.8 6.48716 18.414 6.06449 17.9167 6.00673L17.8 6H16.8V7C16.8 7.55228 16.3523 8 15.8 8C15.2872 8 14.8645 7.61396 14.8068 7.11662L14.8 7V6H8.80005V7C8.80005 7.55228 8.35233 8 7.80005 8C7.28721 8 6.86454 7.61396 6.80678 7.11662L6.80005 7V6H5.80005C5.28721 6 4.86454 6.38604 4.80678 6.88338L4.80005 7V10H18.8ZM23.8 18C23.8 15.2386 21.5615 13 18.8 13C16.0386 13 13.8 15.2386 13.8 18C13.8 20.7614 16.0386 23 18.8 23C21.5615 23 23.8 20.7614 23.8 18ZM18.8 21C20.1063 21 21.2175 20.1652 21.6293 19H18.8C18.2872 19 17.8645 18.614 17.8068 18.1166L17.8 18V15.1707C16.6349 15.5825 15.8 16.6938 15.8 18C15.8 19.6569 17.1432 21 18.8 21ZM19.8 17H21.6293C21.3281 16.1476 20.6525 15.472 19.8 15.1707V17Z"
                              fill="white"
                            />
                          </svg>
                        </div>

                        {type === "63d2c248333d81b9bf1d2121" && (
                          <span className="top-0 left-10 absolute w-3.5 h-3.5 text-green-300 bg-white rounded-full">
                            <CheckCircleIcon />
                          </span>
                        )}
                      </button>
                      <button
                        type="button"
                        className={`flex flex-col items-center relative text-white focus:outline-none focus:ring-2 focus:ring-yellow-300 font-medium rounded-full text-sm text-center ${
                          !alert && "opacity-50 cursor-not-allowed"
                        } ${
                          type === "63d2c24d333d81b9bf1d2123" &&
                          "ring-2 ring-yellow-300"
                        }`}
                        disabled={!alert}
                        onClick={(e) => handleType("63d2c24d333d81b9bf1d2123")}
                      >
                        <div className=" flex items-center justify-center h-14 w-14 rounded-full p-1 flex-shrink-0 bg-[#F8BC67]">
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M19.3824 16.1068L14.3895 6.10353C13.6529 4.62777 11.5472 4.62775 10.8106 6.10352L5.81761 16.1068C5.1539 17.4365 6.12092 19 7.60707 19H17.5929C19.079 19 20.046 17.4365 19.3824 16.1068ZM16.179 5.21036C14.7058 2.25883 10.4943 2.25882 9.02109 5.21033L4.02813 15.2136C2.70072 17.873 4.63476 21 7.60707 21H17.5929C20.5652 21 22.4992 17.8731 21.1718 15.2136L16.179 5.21036Z"
                              fill="white"
                            />
                            <path
                              d="M13.6 8C13.6 7.44772 13.1523 7 12.6 7C12.0477 7 11.6 7.44772 11.6 8V12C11.6 12.5523 12.0477 13 12.6 13C13.1523 13 13.6 12.5523 13.6 12V8Z"
                              fill="white"
                            />
                            <path
                              d="M12.6 15C12.0477 15 11.6 15.4477 11.6 16C11.6 16.5523 12.0477 17 12.6 17C13.1523 17 13.6 16.5523 13.6 16C13.6 15.4477 13.1523 15 12.6 15Z"
                              fill="white"
                            />
                          </svg>
                        </div>

                        {type === "63d2c24d333d81b9bf1d2123" && (
                          <span className="top-0 left-10 absolute w-3.5 h-3.5 text-yellow-300 bg-white rounded-full">
                            <CheckCircleIcon />
                          </span>
                        )}
                      </button>
                      <button
                        type="button"
                        className={`flex flex-col items-center relative text-white focus:outline-none focus:ring-2 focus:ring-red-300 font-medium rounded-full text-sm text-center ${
                          !alert && "opacity-50 cursor-not-allowed"
                        } ${
                          type === "63d2c254333d81b9bf1d2125" &&
                          "ring-2 ring-red-300"
                        }`}
                        disabled={!alert}
                        onClick={(e) => handleType("63d2c254333d81b9bf1d2125")}
                      >
                        <div className="flex items-center justify-center h-14 w-14 rounded-full p-1 flex-shrink-0 bg-[#CF7777]">
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M13.4 8C13.4 7.44772 12.9523 7 12.4 7C11.8477 7 11.4 7.44772 11.4 8V12C11.4 12.5523 11.8477 13 12.4 13C12.9523 13 13.4 12.5523 13.4 12V8ZM12.4 15C11.8477 15 11.4 15.4477 11.4 16C11.4 16.5523 11.8477 17 12.4 17C12.9523 17 13.4 16.5523 13.4 16C13.4 15.4477 12.9523 15 12.4 15Z"
                              fill="white"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.7572 3H14.0428C15.1266 2.99999 16.0008 2.99998 16.7086 3.05782C17.4375 3.11737 18.0777 3.24318 18.67 3.54497C19.6108 4.02433 20.3757 4.78924 20.8551 5.73005C21.1568 6.32234 21.2827 6.96253 21.3422 7.69138C21.4 8.39925 21.4 9.27339 21.4 10.3572V13.6428C21.4 14.7266 21.4 15.6008 21.3422 16.3086C21.2827 17.0375 21.1568 17.6777 20.8551 18.27C20.3757 19.2108 19.6108 19.9757 18.67 20.455C18.0777 20.7568 17.4375 20.8826 16.7086 20.9422C16.0008 21 15.1266 21 14.0429 21H10.7572C9.67342 21 8.79927 21 8.0914 20.9422C7.36255 20.8826 6.72236 20.7568 6.13007 20.455C5.18926 19.9757 4.42436 19.2108 3.94499 18.27C3.6432 17.6777 3.51739 17.0375 3.45784 16.3086C3.40001 15.6007 3.40001 14.7266 3.40002 13.6428V10.3572C3.40001 9.27341 3.40001 8.39926 3.45784 7.69138C3.51739 6.96253 3.6432 6.32234 3.94499 5.73005C4.42436 4.78924 5.18926 4.02433 6.13007 3.54497C6.72236 3.24318 7.36255 3.11737 8.0914 3.05782C8.79928 2.99998 9.67343 2.99999 10.7572 3ZM8.25426 5.05118C7.6491 5.10062 7.30141 5.19279 7.03805 5.32698C6.47357 5.6146 6.01463 6.07354 5.72701 6.63803C5.59282 6.90138 5.50064 7.24907 5.4512 7.85424C5.4008 8.47108 5.40002 9.26339 5.40002 10.4V13.6C5.40002 14.7366 5.4008 15.5289 5.4512 16.1458C5.50064 16.7509 5.59282 17.0986 5.72701 17.362C6.01463 17.9265 6.47357 18.3854 7.03805 18.673C7.30141 18.8072 7.6491 18.8994 8.25426 18.9488C8.87111 18.9992 9.66342 19 10.8 19H14C15.1366 19 15.9289 18.9992 16.5458 18.9488C17.151 18.8994 17.4986 18.8072 17.762 18.673C18.3265 18.3854 18.7854 17.9265 19.073 17.362C19.2072 17.0986 19.2994 16.7509 19.3488 16.1458C19.3992 15.5289 19.4 14.7366 19.4 13.6V10.4C19.4 9.26339 19.3992 8.47108 19.3488 7.85424C19.2994 7.24907 19.2072 6.90138 19.073 6.63803C18.7854 6.07354 18.3265 5.6146 17.762 5.32698C17.4986 5.19279 17.151 5.10062 16.5458 5.05118C15.9289 5.00078 15.1366 5 14 5H10.8C9.66342 5 8.87111 5.00078 8.25426 5.05118Z"
                              fill="white"
                            />
                          </svg>
                        </div>

                        {type === "63d2c254333d81b9bf1d2125" && (
                          <span className="top-0 left-10 absolute w-3.5 h-3.5 text-red-300 bg-white rounded-full">
                            <CheckCircleIcon />
                          </span>
                        )}
                      </button>
                      <button
                        type="button"
                        className={`flex flex-col items-center relative text-white focus:outline-none focus:ring-2 focus:ring-gray-300 font-medium rounded-full text-sm text-center ${
                          !alert && "opacity-50 cursor-not-allowed"
                        } ${
                          type === "63d2c25a333d81b9bf1d2127" &&
                          "ring-2 ring-gray-300"
                        }`}
                        disabled={!alert}
                        onClick={(e) => handleType("63d2c25a333d81b9bf1d2127")}
                      >
                        <div className=" flex items-center justify-center h-14 w-14 rounded-full p-1 flex-shrink-0 bg-[#5D7183]">
                          <svg
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2.19995 12C2.19995 6.47715 6.6771 2 12.2 2C17.7228 2 22.2 6.47715 22.2 12C22.2 17.5228 17.7228 22 12.2 22C6.6771 22 2.19995 17.5228 2.19995 12ZM20.2 12C20.2 7.58172 16.6182 4 12.2 4C7.78167 4 4.19995 7.58172 4.19995 12C4.19995 16.4183 7.78167 20 12.2 20C16.6182 20 20.2 16.4183 20.2 12ZM12.21 7C12.7622 7 13.21 7.44772 13.21 8C13.21 8.51284 12.8239 8.93551 12.3266 8.99327L12.2 9C11.6477 9 11.2 8.55228 11.2 8C11.2 7.48716 11.586 7.06449 12.0833 7.00673L12.21 7ZM13.1932 11.8834C13.1355 11.386 12.7128 11 12.2 11H11.2L11.0833 11.0067C10.586 11.0645 10.2 11.4872 10.2 12C10.2 12.5523 10.6477 13 11.2 13V16L11.2067 16.1166C11.2644 16.614 11.6871 17 12.2 17H13.2L13.3166 16.9933C13.8139 16.9355 14.2 16.5128 14.2 16L14.1932 15.8834C14.1399 15.4243 13.7757 15.06 13.3166 15.0067L13.2 15V12L13.1932 11.8834Z"
                              fill="white"
                            />
                          </svg>
                        </div>

                        {type === "63d2c25a333d81b9bf1d2127" && (
                          <span className="top-0 left-10 absolute w-3.5 h-3.5 text-gray-300 bg-white rounded-full">
                            <CheckCircleIcon />
                          </span>
                        )}
                      </button>
                      <button
                        type="button"
                        className={`flex flex-col items-center relative text-black  focus:outline-none focus:ring-2 focus:ring-black font-medium rounded-full text-sm text-center ${
                          !alert && "opacity-50 cursor-not-allowed"
                        } ${
                          type === "63d2c261333d81b9bf1d2129" &&
                          "ring-2 ring-black"
                        }`}
                        disabled={!alert}
                        onClick={(e) => handleType("63d2c261333d81b9bf1d2129")}
                      >
                        <div className="text-xs flex items-center justify-center h-14 w-14 border border-dashed rounded-full p-1 flex-shrink-0 bg-[#ffffff]">
                          Sem ícone
                        </div>

                        {type === "63d2c261333d81b9bf1d2129" && (
                          <span className="top-0 left-10 absolute w-3.5 h-3.5 text-black bg-white rounded-full">
                            <CheckCircleIcon />
                          </span>
                        )}
                      </button>
                    </div>

                    {/* Data de expiração */}
                    <div
                      className={`flex justify-between ${
                        !alert && "opacity-50 cursor-not-allowed"
                      }`}
                    >
                      <span className="text-sm text-[#46484A] font-medium flex items-center">
                        Data de expiração
                      </span>

                      <div className="relative w-32">
                        <DatePicker
                          disabled={!alert}
                          selected={expirationDate}
                          onChange={(date) => setExpirationDate(date)}
                          type="date"
                          locale="pt-br"
                          dateFormat="dd/MM/yyyy"
                          className="text-[#46484A] bg-gray-50 border border-gray-300 sm:text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5"
                        />

                        <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
                          <CalendarIcon height={20} />
                        </div>
                      </div>
                    </div>

                    {/* Usuários */}
                    {userOptions.length && (
                      <>
                        <label
                          htmlFor="users"
                          className="block text-sm font-black text-[#46484A]"
                        >
                          Usuários
                        </label>

                        <ReactSelect
                          onInputChange={handleInputChange}
                          // defaultValue={[...users]}
                          value={users}
                          isMulti
                          name="users"
                          options={filteredUsers}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={handleChange}
                        />
                      </>
                    )}

                    {/* Filtrar público */}
                    <div>
                      <h1 className="text-base text-[#46484A]">
                        Filtrar público
                      </h1>

                      <div className="mt-4">
                        <div className="flex relative justify-between cursor-pointer mb-4">
                          <span className="text-sm text-[#46484A]">
                            Por perfil
                          </span>
                          <div className="flex items-center">
                            <input
                              id="for_perfil"
                              type="checkbox"
                              value=""
                              className="w-4 h-4 text-[#5D7183] bg-gray-100 rounded-full border-gray-300 focus:ring-gray-500 focus:ring-2"
                              onClick={handleSelectAllProfiles}
                            />
                            <label
                              htmlFor="for_perfil"
                              className="ml-2 text-sm font-medium text-[#5D7183]"
                            >
                              Selecionar todos
                            </label>
                          </div>
                        </div>
                        <div className="flex flex-wrap">
                          <ul className="checkout-button flex flex-wrap">
                            {profileList.map((profile) => (
                              <ProfileListComponent
                                key={profile.id}
                                id={profile.id}
                                description={profile.descricao}
                                isSelected={profile.isSelected}
                              />
                            ))}
                          </ul>
                        </div>
                      </div>

                      <div className="mt-4">
                        <div className="flex relative justify-between cursor-pointer mb-4">
                          <span className="text-sm text-[#46484A]">
                            Por plano
                          </span>
                          <div className="flex items-center">
                            <input
                              id="for_plan"
                              type="checkbox"
                              value=""
                              className="w-4 h-4 text-[#5D7183] bg-gray-100 rounded-full border-gray-300 focus:ring-gray-500 focus:ring-2"
                              onClick={handleSelectAllPlans}
                            />
                            <label
                              htmlFor="for_plan"
                              className="ml-2 text-sm font-medium text-[#5D7183]"
                            >
                              Selecionar todos
                            </label>
                          </div>
                        </div>
                        <div className="flex flex-wrap">
                          <ul className="checkout-button flex flex-wrap">
                            {planList.map((plan) => (
                              <PlanListComponent
                                key={plan.id}
                                id={plan.id}
                                description={plan.descricao}
                                isSelected={plan.isSelected}
                              />
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bg-gray-50 px-4 py-3 text-center sm:px-6">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-slate-800 py-3 px-8 text-sm font-medium text-white shadow-sm hover:bg-{#28343E} focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={handleValidate}
                    >
                      {showModal && (
                        <Modal
                          dataCreateNotification={dataCreateNotification}
                        />
                      )}
                      Salvar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
