import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import api from "../../../services/api";
import { Footer, styles } from "./structurePdf";
import apiNotificationBasicAuth from "../../../helpers/basicAuthApiNotification";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const date = new Date();

export async function OpenPDF(notification) {
  const body = [];

  const { data: notificationData } = await api.get(
    `/notifications/${notification._id}`,
    {
      auth: {
        username: apiNotificationBasicAuth.username,
        password: apiNotificationBasicAuth.password,
      },
    }
  );

  const { data: receivedUsers } = await api.get(
    `/notifications/report/${notification._id}`,
    {
      auth: {
        username: apiNotificationBasicAuth.username,
        password: apiNotificationBasicAuth.password,
      },
    }
  );

  const { data: statusNotification } = await api.get(
    `/status-notification/${notification.status}`,
    {
      auth: {
        username: apiNotificationBasicAuth.username,
        password: apiNotificationBasicAuth.password,
      },
    }
  );

  const { data: typeNotification } = await api.get(
    `/type-notification/${notification.type}`,
    {
      auth: {
        username: apiNotificationBasicAuth.username,
        password: apiNotificationBasicAuth.password,
      },
    }
  );

  const viewed = receivedUsers.filter((user) => user.viewed);
  const read = receivedUsers.filter((user) => user.read);

  if (receivedUsers.length) {
    receivedUsers.map((user) => {
      return body.push([
        { text: user.userId, fontSize: 9, margin: [0, 2, 0, 2] },
        { text: user.userName, fontSize: 9, margin: [0, 2, 0, 2] },
        { text: user.viewed, fontSize: 9, margin: [0, 2, 0, 2] },
        { text: user.read, fontSize: 9, margin: [0, 2, 0, 2] },
      ]);
    });
  }

  const reportTitle = [
    {
      text: "Relatório de notificações por usuários\n\n\n",
      style: "header",
    },
  ];

  const details = [
    {
      table: {
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            {
              text: "Notificação",
              style: "subheader",
            },
            {
              text: `Data de emissão: ${date.toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })} ${date.getHours()}:${date.getMinutes()} \n\n`,
              style: "subheader",
              alignment: "right",
              fontSize: 9,
            },
          ],
        ],
      },
      layout: "noBorders",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*", "*", "*", "*"],
        body: [
          [
            { text: "Título", style: "tableHeader" },
            { text: "Descrição", style: "tableHeader" },
            { text: "Status", style: "tableHeader" },
            { text: "Tipo (Alerta)", style: "tableHeader" },
          ],
          [
            {
              text: notificationData.title,
              fontSize: 9,
              margin: [0, 2, 0, 2],
            },
            {
              text: notificationData.description,
              fontSize: 9,
              margin: [0, 2, 0, 2],
            },
            {
              text: statusNotification.description,
              fontSize: 9,
              margin: [0, 2, 0, 2],
            },
            {
              text:
                typeNotification.description &&
                typeNotification.description + "\n\n\n",
              fontSize: 9,
              margin: [0, 2, 0, 2],
            },
          ],
        ],
      },
      layout: "headerLineOnly",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            {
              text: "Usuários",
              style: "subheader",
            },
            {
              text: `Quantidade de usuários: ${body.length} \n\n`,
              style: "subheader",
              alignment: "right",
              fontSize: 9,
            },
          ],
        ],
      },
      layout: "noBorders",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*", "*", "*", "*"],
        body: [
          [
            { text: "ID usuário", style: "tableHeader" },
            { text: "Username", style: "tableHeader" },
            { text: "Visto", style: "tableHeader" },
            { text: "Lido", style: "tableHeader" },
          ],
          ...body,
        ],
      },
      layout: "headerLineOnly",
    },
    {
      text: "\n\n",
    },
    {
      table: {
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            {
              text: `Vistos: ${viewed.length} \n`,
              style: "subheader",
            },
            {
              text: `Lidos: ${read.length} \n`,
              style: "subheader",
            },
          ],
        ],
      },
      layout: "noBorders",
    },
  ];

  const docDefinitions = {
    pageSize: "A4",
    pageMargins: [15, 50, 15, 40],
    styles: styles,
    header: [reportTitle],
    content: [details],
    footer: Footer,
  };

  pdfMake.createPdf(docDefinitions).open();
}
