import { useContext } from "react";
import { NotificationListContext } from "../contexts/notificationList";

const useNotificationList = () => {
  const context = useContext(NotificationListContext);

  return context;
};

export default useNotificationList;
