import { createContext, useEffect, useState } from "react";

import apiNotificationBasicAuth from "../../helpers/basicAuthApiNotification";
import api from "../../services/api";

export const NotificationListContext = createContext({});

export function NotificationListProvider({ children }) {
  const [alert, setAlert] = useState();
  const [automatic, setAutomatic] = useState();
  const [searchField, setSearchField] = useState("");
  const [sortedDocuments, setSortedDocuments] = useState(-1);
  const [filterFinalDate, setFilterFinalDate] = useState("");
  const [notificationList, setNotificationList] = useState([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [filterInitialDate, setFilterInitialDate] = useState("");
  const [notificationsLength, setNotificationsLength] = useState(0);
  const [skipPage, setSkipPage] = useState(0);
  const [duplicateNotification, setDuplicateNotification] = useState();

  useEffect(() => {
    api
      .get(`/notifications?skip=${skipPage}&sortedDocuments=${sortedDocuments}`, {
        auth: {
          username: apiNotificationBasicAuth.username,
          password: apiNotificationBasicAuth.password,
        },
      })
      .then((response) => {
        setNotificationList(response);
      })
      .catch((error) => {
        console.log("🚀 ~ error:", error);
        // signOut();
      });
  }, []);

  async function handleNotificationList(
    skipPage,
    sortedDocuments,
    searchField,
    filterInitialDate,
    filterFinalDate,
    alert,
    automatic
  ) {
    setSkipPage((currentPageNumber - 1) * 10);
    setSortedDocuments(sortedDocuments);

    return await api
      .get(`/notifications`, {
        auth: {
          username: apiNotificationBasicAuth.username,
          password: apiNotificationBasicAuth.password,
        },
        params: {
          skip: skipPage,
          sortedDocuments,
          search: searchField,
          filterInitialDate,
          filterFinalDate,
          ...(alert && { alert }),
          ...(automatic && { automatic }),
        },
      })
      .then((response) => {
        setNotificationsLength(response.data.length);
        setNotificationList(response);
      })
      .catch((error) => {
        console.log("🚀 ~ error:", error);
        // signOut();
      });
  }

  return (
    <NotificationListContext.Provider
      value={{
        alert,
        setAlert,
        automatic,
        setAutomatic,
        skipPage,
        setSkipPage,
        searchField,
        setSearchField,
        sortedDocuments,
        filterFinalDate,
        notificationList,
        currentPageNumber,
        filterInitialDate,
        setFilterFinalDate,
        setSortedDocuments,
        setNotificationList,
        notificationsLength,
        setFilterInitialDate,
        setCurrentPageNumber,
        duplicateNotification,
        handleNotificationList,
        setDuplicateNotification,
      }}
    >
      {children}
    </NotificationListContext.Provider>
  );
}
