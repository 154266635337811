import { createContext, useEffect, useState } from "react";

export const FilterNotificationsContext = createContext({});

export function FilterNotificationsProvider({ children }) {
  const [filter, setFilter] = useState({});
  console.log("🚀 ~ filter:", filter);

  useEffect(() => {
    localStorage.removeItem("filter_notifications");
  }, []);

  async function clearFilter() {
    setFilter({});
    localStorage.removeItem("filter_notifications");
  }

  function submit({ filterInitialDate, filterFinalDate, alert, automatic }) {
    localStorage.setItem(
      "filter_notifications",
      JSON.stringify({ filterInitialDate, filterFinalDate, alert, automatic })
    );
    setFilter({
      filterInitialDate,
      filterFinalDate,
      alert,
      automatic,
    });

    return filter;
  }

  return (
    <FilterNotificationsContext.Provider
      value={{ filter, setFilter, submit, clearFilter }}
    >
      {children}
    </FilterNotificationsContext.Provider>
  );
}
